.box-border{
  border-radius: 5px;
  border: 1px solid #AEAEAE;
  .title{
    background-color: $MainColor;
    border-top-right-radius:5px;
    border-bottom-left-radius:5px;
    display: inline-block;
  }
  .head{
    background-color: $MainColor;
    border-radius: 5px 5px 0 0 !important;
    color: white;
  }
  .price{
    width:100%;
    text-align: center;
    border: 1px solid #558EFA;
    border-radius: 5px;
    color:#558EFA;
    font-size: .9rem;
    font-weight: 600;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 2rem;
  }
}
.headColor {
  background: linear-gradient(90deg, $ColorMainDark2 0%, $ColorMainDark 100%);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.button-car{
  background-color: #fff;
  border:1px solid $MainColor;
  color:$MainColor;
  border-radius: 10px;
}
.box-detail{
  border-radius: 5px;
  box-shadow: 1px 1px 8px #9a9a9a75;
  background-color: #fff;
  text-align: right;
  &:hover{
    box-shadow: 1px 1px 8px #9a9a9a75;
  }
  &:focus{
    box-shadow: 1px 1px 8px #9a9a9a75;
  }
  .box-detail-item{
    color: #bfc1c5;
  }
}
.btn-check-now{
  background-color: #558EFA;
  border-radius: 5px;
  color: #fff;
}
.btn-check-now-gray{
  background-color: #eee;
  border-radius: 5px;
  color: #515151;
}
