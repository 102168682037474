.form-check-inline-style{
  label{
    padding-right:25px;
  }
  .check{
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    top:calc(50% - 9px);
    right:2px;
    border: 2px solid $MainColor;
    z-index: 0;
    @include transition(all .3s);
    &:after{
      content: '';
      position: absolute;
      width:0;
      height: 0;
      background-color: $MainColor;
      opacity: 0;
      top:50%;
      right:50%;
      border-radius: 50%;
      @include transition(all .3s);
    }
  }
  .checkbox-style {
    position: absolute;
    top:calc(50% - 8px);
    right:0;
    opacity:0;
    z-index: 1;
    cursor: pointer;
    width: 100%;
    height: 100%;
    &:checked {
      ~ .check {
        &:after{
          width: calc(100% + 2px);
          height:  calc(100% + 2px);
          opacity: 1;
          top:-1px;
          right:-1px;
        }

      }
    }
  }
}

