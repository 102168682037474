@mixin flex($direction,$wrap,$align,$justify){
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  // row | row-reverse | column | column-reverse
  -webkit-flex-direction: $direction;
  -moz-flex-direction: $direction;
  -ms-flex-direction: $direction;
  flex-direction: $direction;

  // nowrap | wrap | wrap-reverse
  -webkit-flex-wrap: $wrap;
  -moz-flex-wrap: $wrap;
  -ms-flex-wrap: $wrap;
  flex-wrap: $wrap;

  // flex-login | flex-end | center | baseline | stretch
  -webkit-align-items: $align;
  -moz-align-items: $align;
  -ms-align-items: $align;
  -ms-flex-align: $align;
  align-items: $align;
  // flex-login | flex-end | center | space-between | space-around
  -webkit-justify-content: $justify;
  -moz-justify-content: $justify;
  -ms-justify-content: $justify;
  justify-content: $justify;
  -ms-flex-pack: $justify;
}
@mixin flex-grow($grow) {
  -webkit-flex-grow: $grow;
  -moz-flex-grow: $grow;
  -ms-flex-grow: $grow;
  flex-grow: $grow;
}
@mixin align-self($align) {
  -webkit-align-self: $align;
  -moz-align-self: $align;
  -ms-align-self: $align;
  align-self: $align;
}
@mixin transition{
  -webkit-transition:all .3s,width .3s,opacity .4s,border .1s,margin 0s,background .3s,width .5s,height .5s;
  -moz-transition: all .3s,width .3s,opacity .4s,border .1s,margin 0s,background .3s,width .5s,height .5s;
  -ms-transition:all .3s,width .3s,opacity .4s,border .1s,margin 0s,background .3s,width .5s,height .5s;
  -o-transition: all .3s,width .3s,opacity .4s,border .1s,margin 0s,background .3s,width .5s,height .5s;
  transition: all .3s,width .3s,opacity .4s,border .1s,margin 0s,background .3s,width .5s,height .5s;
}
@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}
/*@mixin transform($degree){
  -moz-transform: rotate($degree);
  -ms-transform: rotate($degree);
  -o-transform: rotate($degree);
  -webkit-transform: rotate($degree);
  transform: rotate($degree);
}*/
@mixin media-max($break-large){
  @media screen and (max-width: $break-large) {
    @content
  }
}
@mixin media-min($break-large){
  @media screen and (min-width: $break-large) {
    @content
  }
}
@mixin media-height-max($break-large){
  @media screen and (max-height: $break-large) {
    @content
  }
}
@mixin media-height-min($break-large){
  @media screen and (min-height: $break-large) {
    @content
  }
}



@mixin font-source-sans($size: false, $colour: false, $weight: false,  $lh: false) {
  @if $size { font-size: $size; }
  @if $colour { color: $colour; }
  @if $weight { font-weight: $weight; }
  @if $lh { line-height: $lh; }
}
@mixin input-placeholder {
  &.placeholder { @content; }
  &:-moz-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &:-ms-input-placeholder { @content; }
  &::-webkit-input-placeholder { @content; }
}
/*
input,
textarea {
  @include input-placeholder {
    color: $grey;
  }
}
*/
@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity = $opacity-ie); //IE8
}
@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }

  @-moz-keyframes #{$name} {
    @content;
  }

  @keyframes #{$name} {
    @content;
  }
}
@mixin gradient( $orientation,$start-color, $end-color) {
  background: $start-color;
  @if $orientation == 'vertical' {
    background: -webkit-linear-gradient(top, $start-color, $end-color);
    background: linear-gradient(to bottom, $start-color, $end-color);
  } @else if $orientation == 'horizontal' {
    background: -webkit-linear-gradient(left, $start-color, $end-color);
    background: linear-gradient(to right, $start-color, $end-color);
  } @else {
    background: -webkit-radial-gradient(center, ellipse cover, $start-color, $end-color);
    background: radial-gradient(ellipse at center, $start-color, $end-color);
  }
}
@mixin radius($border-radius){
  -webkit-border-radius:$border-radius;
  -moz-border-radius: $border-radius;
  border-radius: $border-radius;
}
@mixin box-shadow($value) {
  -webkit-box-shadow:$value;
  -moz-box-shadow: $value;
  box-shadow: $value;
}
@mixin animation ($delay, $duration) {
  -webkit-animation-delay: $delay;
  -webkit-animation-duration: $duration;
  /*-webkit-animation-name: $animation;*/
  -webkit-animation-fill-mode: both; /* this prevents the animation from restarting! */

  -moz-animation-delay: $delay;
  -moz-animation-duration: $duration;
  /*  -moz-animation-name: $animation;*/
  -moz-animation-fill-mode: both; /* this prevents the animation from restarting! */

  -o-animation-delay: $delay;
  -o-animation-duration: $duration;
  /* -o-animation-name: $animation;*/
  -o-animation-fill-mode:both; /* this prevents the animation from restarting! */

  animation-delay: $delay;
  animation-duration: $duration;
  /*  animation-name: $animation;*/
  animation-fill-mode: both; /* this prevents the animation from restarting! */

}
@mixin animations($delay, $duration , $animation,$count,$timing) {
  -webkit-animation-delay: $delay;
  -webkit-animation-duration: $duration;
  -webkit-animation-name: $animation;
  -webkit-animation-timing-function:$timing;
  -webkit-animation-fill-mode: both; /* this prevents the animation from restarting! */
  -webkit-animation-iteration-count:$count ;
  -moz-animation-delay: $delay;
  -moz-animation-duration: $duration;
  -moz-animation-name: $animation;
  -moz-animation-timing-function:$timing;
  -moz-animation-fill-mode: both; /* this prevents the animation from restarting! */
  -moz-animation-iteration-count:$count ;
  -o-animation-delay: $delay;
  -o-animation-duration: $duration;
  -o-animation-name: $animation;
  -o-animation-timing-function:$timing;
  -o-animation-fill-mode:both; /* this prevents the animation from restarting! */
  -o-animation-iteration-count:$count ;

  animation-delay: $delay;
  animation-duration: $duration;
  animation-name: $animation;
  animation-timing-function:$timing;
  animation-fill-mode: both; /* this prevents the animation from restarting! */
  animation-iteration-count:$count ;

}
@mixin animation3($delay, $duration ,$count) {
  -webkit-animation-delay: $delay;
  -webkit-animation-duration: $duration;
  -webkit-animation-fill-mode: both; /* this prevents the animation from restarting! */
  -webkit-animation-iteration-count:$count ;
  -moz-animation-delay: $delay;
  -moz-animation-duration: $duration;
  -moz-animation-fill-mode: both; /* this prevents the animation from restarting! */
  -moz-animation-iteration-count:$count ;
  -o-animation-delay: $delay;
  -o-animation-duration: $duration;
  -o-animation-fill-mode:both; /* this prevents the animation from restarting! */
  -o-animation-iteration-count:$count ;

  animation-delay: $delay;
  animation-duration: $duration;
  animation-fill-mode: both; /* this prevents the animation from restarting! */
  animation-iteration-count:$count ;

}
@mixin keyframe{
  -webkit-animation-name: op;
  -moz-animation-name:op;
  -o-animation-name: op;
  animation-name: op;
  @-webkit-keyframes op {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes op{
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @-o-keyframes op {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes op {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}
@mixin keyframe-rotate{
  -webkit-animation-name: l;
  -moz-animation-name:l;
  -o-animation-name: l;
  animation-name: l;
  @-webkit-keyframes l {
    0% {
      @include transform(0deg);
    }

    100% {
      @include transform(360deg);
    }
  }

  @-moz-keyframes l{
    0% {
      @include transform(0deg);
    }

    100% {
      @include transform(360deg);
    }
  }

  @-o-keyframes l {
    0% {
      @include transform(0deg);
    }

    100% {
      @include transform(360deg);
    }
  }

  @keyframes l {
    0% {
      @include transform(0deg);
    }

    100% {
      @include transform(360deg);
    }
  }
}
@mixin ripple{
  -webkit-animation-name: l1;
  -moz-animation-name:l1;
  -o-animation-name: l1;
  animation-name: l1;
  @-webkit-keyframes l1 {
    0%{
      transform: scale(0.8);
    }
    50%{
      transform: scale(1.07);
    }

    100%{
      transform: scale(1);
    }
  }

  @-moz-keyframes l1{
    0%{
      transform: scale(0.8);
    }

    50%{
      transform: scale(1.07);
    }

    100%{
      transform: scale(1);
    }
  }

  @-o-keyframes l1 {
    0%{
      transform: scale(0.8);
    }

    50%{
      transform: scale(1.07);
    }

    100%{
      transform: scale(1);
    }
  }




  @keyframes l1 {
    0%{
      transform: scale(0.8);
    }

    50%{
      transform: scale(1.07);
    }

    100%{
      transform: scale(1);
    }
  }
}
@mixin placeholder{
  &::placeholder {
    color: #cac6c6;
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #cac6c6;
  }

  &::-ms-input-placeholder { /* Microsoft Edge */
    color: #cac6c6;
  }
}
@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}

@mixin loading-animation{
  -webkit-animation-name: sk-bouncedelay;
  -moz-animation-name:sk-bouncedelay;
  -o-animation-name: sk-bouncedelay;
  animation-name: sk-bouncedelay;
  @-webkit-keyframes sk-bouncedelay {
    0%,80%,100%{@include transform(scale(0))}
    40%{@include transform(scale(1))}
  }

  @-moz-keyframes sk-bouncedelay{
    0%,80%,100%{@include transform(scale(0))}
    40%{@include transform(scale(1))}
  }

  @-o-keyframes sk-bouncedelay {
    0%,80%,100%{@include transform(scale(0))}
    40%{@include transform(scale(1))}
  }

  @keyframes sk-bouncedelay {
    0%,80%,100%{@include transform(scale(0));}
    40%{@include transform(scale(1));}
  }
}
@mixin bounceIn{
  -webkit-animation-name: bounce;
  -moz-animation-name:bounce;
  -o-animation-name: bounce;
  animation-name: bounce;


  @-webkit-keyframes bounce {
    0%, 20%, 40%, 60%, 80%, 100% {
      -webkit-animation-timing-function: cubic-bezier(0.215,0.61,0.355,1);
      animation-timing-function: cubic-bezier(0.215,0.61,0.355,1);
    }
    0% {
      opacity: 0;
      -webkit-transform: scale3d(0.3,0.3,0.3);
      @include transform(scale3d(0.3,0.3,0.3))
    }
    20% {
      -webkit-transform: scale3d(1.1,1.1,1.1);
      @include transform(scale3d(1.1,1.1,1.1));
    }
    40% {
      -webkit-transform: scale3d(0.9,0.9,0.9);
      @include transform(scale3d(0.9,0.9,0.9));
    }
    60% {
      opacity: 1;
      -webkit-transform: scale3d(1.03,1.03,1.03);
      @include transform(scale3d(1.03,1.03,1.03));
    }
    80% {
      -webkit-transform: scale3d(0.97,0.97,0.97);
      @include transform(scale3d(0.97,0.97,0.97));
    }
    100% {
      opacity: 1;
      -webkit-transform: scale3d(1,1,1);
      @include transform(scale3d(1,1,1));
    }
  }

  @-moz-keyframes bounce{
    0%, 20%, 40%, 60%, 80%, 100% {
      -webkit-animation-timing-function: cubic-bezier(0.215,0.61,0.355,1);
      animation-timing-function: cubic-bezier(0.215,0.61,0.355,1);
    }
    0% {
      opacity: 0;
      -webkit-transform: scale3d(0.3,0.3,0.3);
      @include transform(scale3d(0.3,0.3,0.3))
    }
    20% {
      -webkit-transform: scale3d(1.1,1.1,1.1);
      @include transform(scale3d(1.1,1.1,1.1));
    }
    40% {
      -webkit-transform: scale3d(0.9,0.9,0.9);
      @include transform(scale3d(0.9,0.9,0.9));
    }
    60% {
      opacity: 1;
      -webkit-transform: scale3d(1.03,1.03,1.03);
      @include transform(scale3d(1.03,1.03,1.03));
    }
    80% {
      -webkit-transform: scale3d(0.97,0.97,0.97);
      @include transform(scale3d(0.97,0.97,0.97));
    }
    100% {
      opacity: 1;
      -webkit-transform: scale3d(1,1,1);
      @include transform(scale3d(1,1,1));
    }
  }

  @-o-keyframes bounce {
    0%, 20%, 40%, 60%, 80%, 100% {
      -webkit-animation-timing-function: cubic-bezier(0.215,0.61,0.355,1);
      animation-timing-function: cubic-bezier(0.215,0.61,0.355,1);
    }
    0% {
      opacity: 0;
      -webkit-transform: scale3d(0.3,0.3,0.3);
      @include transform(scale3d(0.3,0.3,0.3))
    }
    20% {
      -webkit-transform: scale3d(1.1,1.1,1.1);
      @include transform(scale3d(1.1,1.1,1.1));
    }
    40% {
      -webkit-transform: scale3d(0.9,0.9,0.9);
      @include transform(scale3d(0.9,0.9,0.9));
    }
    60% {
      opacity: 1;
      -webkit-transform: scale3d(1.03,1.03,1.03);
      @include transform(scale3d(1.03,1.03,1.03));
    }
    80% {
      -webkit-transform: scale3d(0.97,0.97,0.97);
      @include transform(scale3d(0.97,0.97,0.97));
    }
    100% {
      opacity: 1;
      -webkit-transform: scale3d(1,1,1);
      @include transform(scale3d(1,1,1));
    }
  }

  @keyframes bounce {
    0%, 20%, 40%, 60%, 80%, 100% {
      -webkit-animation-timing-function: cubic-bezier(0.215,0.61,0.355,1);
      animation-timing-function: cubic-bezier(0.215,0.61,0.355,1);
    }
    0% {
      opacity: 0;
      -webkit-transform: scale3d(0.3,0.3,0.3);
      @include transform(scale3d(0.3,0.3,0.3))
    }
    20% {
      -webkit-transform: scale3d(1.1,1.1,1.1);
      @include transform(scale3d(1.1,1.1,1.1));
    }
    40% {
      -webkit-transform: scale3d(0.9,0.9,0.9);
      @include transform(scale3d(0.9,0.9,0.9));
    }
    60% {
      opacity: 1;
      -webkit-transform: scale3d(1.03,1.03,1.03);
      @include transform(scale3d(1.03,1.03,1.03));
    }
    80% {
      -webkit-transform: scale3d(0.97,0.97,0.97);
      @include transform(scale3d(0.97,0.97,0.97));
    }
    100% {
      opacity: 1;
      -webkit-transform: scale3d(1,1,1);
      @include transform(scale3d(1,1,1));
    }
  }
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
@-moz-keyframes mspin { 100% { -moz-transform: rotate(-360deg); } }
@-webkit-keyframes mspin { 100% { -webkit-transform: rotate(-360deg); } }
@keyframes mspin { 100% { -webkit-transform: rotate(-360deg); transform:rotate(-360deg); } }
