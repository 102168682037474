.main-page {
  height: 100vh;
  background-color: #F4F5F9;
  overflow: hidden;
}

.contents {
  height: calc(100% - 60px);
  overflow-y: scroll;
  direction: ltr;
  .contents-inside{
    direction: rtl;
  }
}
.main-page-inside{
  box-shadow: 1px 1px 8px #9a9a9a75;
  background-color: #fff;
  border-radius: 5px;
padding: 2rem;

}
.main-title{
  color:$colorBlack;
  position: relative;
  padding-right: 11px;
  font-weight: 600;
  font-size: 1.1rem;
  &:before{
    content: '';
    position: absolute;
    width: 4px ;
    height: 100%;
    right: 0;
    background-color: $MainColor;
    border-radius: 10px;
  }
}


.btn-return-page{
  position : absolute !important;
  top: 70px !important;
  left: 15px !important;
  z-index: 99999 !important;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $ColorMainDark;
  background: linear-gradient(90deg, $ColorMainDark2 0%, $ColorMainDark 100%);
  width: 70px;
  height: 30px ;
  border-radius: $border-radius-5;
  padding: .85rem 0;
  text-align: center;
  @include transition(all .3s);

  &:hover {
    color: #fff;
    background: linear-gradient(90deg, $ColorMainDark2 0%, $ColorMainDark 0%);
  }
}
