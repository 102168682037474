.caption-dark-bold {
  color: #6b6b6b;
  font-size: 1rem;
  font-weight: 600;
}

.caption-dark {
  color: #6b6b6b;
  font-size: 1rem;
}

.iconInput {
  position: absolute;
  top: -7px;
  color: $ColorMainDark;
}
.submit-btn-border {
  color: $ColorMainDark2;
  display: block;
  background-color: #fff;
  width: 100%;
  border-radius: 100px;
  padding: .85rem 0;
  text-align: center;
  border: 1px solid $ColorMainDark2;
  @include transition(all .3s);

  &:hover {
    color: #fff;
    border: 1px solid $ColorMainDark2;
    background: $ColorMainDark2;
    @include transition(all .3s);
  }
}
.submit-btn {
  color: #fff;
  display: block;
  background: $ColorMainDark;
  background: linear-gradient(90deg, $ColorMainDark2 0%, $ColorMainDark 100%);
  width: 100%;
  border-radius: $border-radius-5;
  padding: .85rem 0;
  text-align: center;
  @include transition(all .3s);

  &:hover {
    color: #fff;
    background: linear-gradient(90deg, $ColorMainDark2 0%, $ColorMainDark 0%);
  }
}

.submit-btn-silver {
  background: $silver;
  display: block;
  color: #fff;
  width: 100%;
  border-radius: $border-radius-5;
  padding: .85rem 0;
  text-align: center;
  @include transition(all .3s);
  cursor: not-allowed;

  &:hover {
    color: #fff;
  }
}

.submit-btn-red {
  color: #fff;
  display: block;
  background: rgb(2, 0, 36);
  background: linear-gradient(90deg, #ff090f47 0%, #ff4040 100%);
  width: 100%;
  border-radius: $border-radius-5;
  padding: .85rem 0;
  text-align: center;
  @include transition(all .3s);

  &:hover {
    color: #fff;
    background: linear-gradient(90deg, #ff090f47 0%, red 0%);
  }
}
.submit-btn-blue-border {
  color: #21a7da;
  display: block;
  background-color: #fff;
  width: 100%;
  border-radius: 100px;
  padding: .85rem 0;
  text-align: center;
  border: 1px solid #21a7da;
  @include transition(all .3s);

  &:hover {
    color: #fff;
    border: 1px solid #21a7da;
    background: #21a7da;
    @include transition(all .3s);
  }
}
.submit-btn-blue {
  color: #fff;
  display: block;
  background: rgb(2, 0, 36);
  background: linear-gradient(90deg, #2ea9dd4d 0%, #21a7da 100%);
  width: 100%;
  border-radius: $border-radius-5;
  padding: .85rem 0;
  text-align: center;
  @include transition(all .3s);

  &:hover {
    color: #fff;
    background: linear-gradient(90deg, #9fdaf3 0%, #21a7da 0%);
  }
}

.submit-btn-green-border {
  color: #09964f;
  display: block;
  background-color: #fff;
  width: 100%;
  border-radius: 100px;
  padding: .85rem 0;
  text-align: center;
  border: 1px solid #09964f;
  @include transition(all .3s);

  &:hover {
    color: #fff;
    border: 1px solid #68ef91;
    background: #68ef91;
    @include transition(all .3s);
  }
}


.submit-btn-green {
  color: #fff;
  display: block;
  background: rgb(2, 0, 36);
  background: linear-gradient(90deg, #09964f 0%, #68ef91 100%);
  width: 100%;
  border-radius: $border-radius-5;
  padding: .85rem 0;
  text-align: center;
  @include transition(all .3s);

  &:hover {
    color: #fff;
    background: linear-gradient(90deg, #09964f 0%, #68ef91 0%);
    @include transition(all .3s);
  }
}

.timer {
  color: $colorBlack;
  border-left: 1px solid #b7b7b7;
  min-width: 50px;
  display: inline-block;
}

.btn-car {
  box-shadow: 0 0 4px 1px #75757552;
  background-color: #fff;
  color: $colorGray;
  border-radius: $border-radius-10;
  width: 100%;
  display: block;
  height: 100%;
  @include transition(all .2s);

  &:focus {
    box-shadow: 0 0 4px 1px $MainColor;
    color: $MainColor;
    background-color: #fd886912;
  }
}

.headColor {
  background: linear-gradient(90deg, $ColorMainDark2 0%, $ColorMainDark 100%);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.headColorMain {
  background: $ColorMainDark;
  background: linear-gradient(90deg, $ColorMainDark 0%, $ColorMainDark2 50%, $ColorMainDark 100%);
}

.box-main {
  background-color: $BackgroundPublic !important;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

//
.login-box{
  min-height: 98vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .inside-login{
    height: 100%;
    background-color: #fff;
    width: 100%;
    text-align: center;
    border-radius: 5px;
    box-shadow: 1px 1px 8px #9a9a9a75;
    .title-login{
      color:$colorBlack;
      font-size: 1.5rem;

    }
    .text{
     min-width: 100px;
    }
  }
}
.submit-btn {
  color: #fff;
  display: block;
  background: $ColorMainDark;
  background: linear-gradient(90deg, $ColorMainDark2 0%, $ColorMainDark 100%);
  width: 100%;
  border-radius: $border-radius-5;
  padding: .85rem 0;
  text-align: center;
  @include transition(all .3s);

  &:hover {
    color: #fff;
    background: linear-gradient(90deg, $ColorMainDark2 0%, $ColorMainDark 0%);
  }
}
.submit-btn-border {
  color: $ColorMainDark2;
  display: block;
  background-color: #fff;
  width: 100%;
  border-radius: 5px;
  padding: .85rem 0;
  text-align: center;
  border: 1px solid $ColorMainDark2;
  @include transition(all .3s);

  &:hover {
    color: #fff;
    border: 1px solid $ColorMainDark2;
    background: $ColorMainDark2;
    @include transition(all .3s);
  }
}
.submit-btn-blue-border {
  color: #21a7da;
  display: block;
  background-color: #fff;
  width: 100%;
  border-radius:5px;
  padding: .85rem 0;
  text-align: center;
  border: 1px solid #21a7da;
  @include transition(all .3s);

  &:hover {
    color: #fff;
    border: 1px solid #21a7da;
    background: #21a7da;
    @include transition(all .3s);
  }
}
.submit-btn-blue {
  color: #fff;
  display: block;
  background: rgb(2, 0, 36);
  background: linear-gradient(90deg, #2ea9dd4d 0%, #21a7da 100%);
  width: 100%;
  border-radius: $border-radius-5;
  padding: .85rem 0;
  text-align: center;
  @include transition(all .3s);

  &:hover {
    color: #fff;
    background: linear-gradient(90deg, #9fdaf3 0%, #21a7da 0%);
  }
}
.caption-dark-bold {
  color: #6b6b6b;
  font-size: 1rem;
  font-weight: 600;
}

.caption-dark {
  color: #6b6b6b;
  font-size: 1rem;
}

.iconInput {
  position: absolute;
  top: -7px;
  color: $ColorMainDark;
}
.submit-btn-border {
  color: $ColorMainDark2;
  display: block;
  background-color: #fff;
  width: 100%;
  border-radius: 5px;
  padding: .85rem 0;
  text-align: center;
  border: 1px solid $ColorMainDark2;
  @include transition(all .3s);

  &:hover {
    color: #fff;
    border: 1px solid $ColorMainDark2;
    background: $ColorMainDark2;
    @include transition(all .3s);
  }
}
.submit-btn {
  color: #fff;
  display: block;
  background: $ColorMainDark;
  background: linear-gradient(90deg, $ColorMainDark2 0%, $ColorMainDark 100%);
  width: 100%;
  border-radius: $border-radius-5;
  padding: .85rem 0;
  text-align: center;
  @include transition(all .3s);

  &:hover {
    color: #fff;
    background: linear-gradient(90deg, $ColorMainDark2 0%, $ColorMainDark 0%);
  }
}

.submit-btn-silver {
  background: $silver;
  display: block;
  color: #fff;
  width: 100%;
  border-radius: $border-radius-5;
  padding: .85rem 0;
  text-align: center;
  @include transition(all .3s);
  cursor: not-allowed;

  &:hover {
    color: #fff;
  }
}

.submit-btn-red {
  color: #fff;
  display: block;
  background: rgb(2, 0, 36);
  background: linear-gradient(90deg, #ff090f47 0%, #ff4040 100%);
  width: 100%;
  border-radius: $border-radius-5;
  padding: .85rem 0;
  text-align: center;
  @include transition(all .3s);

  &:hover {
    color: #fff;
    background: linear-gradient(90deg, #ff090f47 0%, red 0%);
  }
}
.submit-btn-blue-border {
  color: #21a7da;
  display: block;
  background-color: #fff;
  width: 100%;
  border-radius: 5px;
  padding: .85rem 0;
  text-align: center;
  border: 1px solid #21a7da;
  @include transition(all .3s);

  &:hover {
    color: #fff;
    border: 1px solid #21a7da;
    background: #21a7da;
    @include transition(all .3s);
  }
}
.submit-btn-blue {
  color: #fff;
  display: block;
  background: rgb(2, 0, 36);
  background: linear-gradient(90deg, #2ea9dd4d 0%, #21a7da 100%);
  width: 100%;
  border-radius: $border-radius-5;
  padding: .85rem 0;
  text-align: center;
  @include transition(all .3s);

  &:hover {
    color: #fff;
    background: linear-gradient(90deg, #9fdaf3 0%, #21a7da 0%);
  }
}

.submit-btn-green-border {
  color: #09964f;
  display: block;
  background-color: #fff;
  width: 100%;
  border-radius: 5px;
  padding: .85rem 0;
  text-align: center;
  border: 1px solid #09964f;
  @include transition(all .3s);

  &:hover {
    color: #fff;
    border: 1px solid #68ef91;
    background: #68ef91;
    @include transition(all .3s);
  }
}


.submit-btn-green {
  color: #fff;
  display: block;
  background: rgb(2, 0, 36);
  background: linear-gradient(90deg, #09964f 0%, #68ef91 100%);
  width: 100%;
  border-radius: $border-radius-5;
  padding: .85rem 0;
  text-align: center;
  @include transition(all .3s);

  &:hover {
    color: #fff;
    background: linear-gradient(90deg, #09964f 0%, #68ef91 0%);
    @include transition(all .3s);
  }
}

.timer {
  color: $colorBlack;
  border-left: 1px solid #b7b7b7;
  min-width: 50px;
  display: inline-block;
}

.btn-car {
  box-shadow: 0 0 4px 1px #75757552;
  background-color: #fff;
  color: $colorGray;
  border-radius: $border-radius-10;
  width: 100%;
  display: block;
  height: 100%;
  @include transition(all .2s);

  &:focus {
    box-shadow: 0 0 4px 1px $MainColor;
    color: $MainColor;
    background-color: #fd886912;
  }
}

.headColor {
  background: linear-gradient(90deg, $ColorMainDark2 0%, $ColorMainDark 100%);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.headColorMain {
  background: $ColorMainDark;
  background: linear-gradient(90deg, $ColorMainDark 0%, $ColorMainDark2 50%, $ColorMainDark 100%);
}

.box-main {
  background-color: $BackgroundPublic !important;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.profile-image {
  width: 30px;
  height: 30px;
  background-color: #fff3cd;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
  border: 1px solid #eee;
  border-radius: 50%;
}
.box-gold{
  background-color: #FFF7EB;
  border-radius: 50px;
  font-size: .9rem;
  padding: 5px 10px;
}
