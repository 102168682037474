
@font-face {
	font-family: IRANSans_light;
	font-style: normal;
	font-weight: 300;
	src: url('../fonts/eot/IRANSansWeb_Light.eot');
	src: url('../fonts/eot/IRANSansWeb_Light.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
		 url('../fonts/woff2/IRANSansWeb_Light.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('../fonts/woff/IRANSansWeb_Light.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
		 url('../fonts/ttf/IRANSansWeb_Light.ttf') format('truetype');
}

@font-face {
	font-family: IRANSans_light;
	font-style: normal;
	font-weight: normal;
	src: url('../fonts/eot/IRANSansWeb(FaNum).eot');
	src: url('../fonts/eot/IRANSansWeb(FaNum).eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
	url('../fonts/woff2/IRANSansWeb(FaNum).woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
	url('../fonts/woff/IRANSansWeb(FaNum).woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
	url('../fonts/ttf/IRANSansWeb(FaNum).ttf') format('truetype');
}
@font-face {
	font-family: IRANSans;
	font-style: normal;
	font-weight: normal;
	src: url('../fonts/eot/IRANSansWeb.eot');
	src: url('../fonts/eot/IRANSansWeb.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
	url('../fonts/woff2/IRANSansWeb.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
	url('../fonts/woff/IRANSansWeb.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
	url('../fonts/ttf/IRANSansWeb.ttf') format('truetype');
}
