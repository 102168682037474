@keyframes ldio-tft879wxl8 {
  0% {
    top: 96px;
    left: 96px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 18px;
    left: 18px;
    width: 156px;
    height: 156px;
    opacity: 0;
  }
}

.ldio-tft879wxl8 div {
  position: absolute;
  border-width: 4px;
  border-style: solid;
  opacity: 1;
  border-radius: 50%;
  animation: ldio-tft879wxl8 .5s cubic-bezier(0,0.2,0.8,1) infinite;
  -webkit-animation: ldio-tft879wxl8 .5s cubic-bezier(0,0.2,0.8,1) infinite;
  -moz-animation: ldio-tft879wxl8 .5s cubic-bezier(0,0.2,0.8,1) infinite;
  -o-animation: ldio-tft879wxl8 .5s cubic-bezier(0,0.2,0.8,1) infinite;
  -ms-transition: ldio-tft879wxl8 .5s cubic-bezier(0,0.2,0.8,1) infinite;
}

.ldio-tft879wxl8 div:nth-child(1) {
  border-color: #fe9901
}

.ldio-tft879wxl8 div:nth-child(2) {
  border-color: #e3ca92;
  animation-delay: -0.5s;
}

.loadingio-spinner-ripple-ndkueme4em {
  margin-top: 200px;
  width: 100px;
  height: 100px;
  display: inline-block;
  overflow: hidden;
  background: transparent;
}
.ldio-tft879wxl8 {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(.5);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-tft879wxl8 div { box-sizing: content-box; }
/* generated by https://loading.io/ */
