.number-404{
  font-size: 5rem;
  color: $MainColor;
}
.caption-404{
  font-size: 2rem;
  color: $colorBlack;
}
.not-found-file{
  font-size:5rem;
  color:#eee;
  line-height: 3rem;
}
.text-not-found-file{
  font-size:1rem;
  color: #bfbfbf;
}
