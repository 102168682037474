.text-banner {
  min-width: 90px;
}

.img-banner {
  max-width: 70px;
  width: 100%;
  height: auto;
}

.link-banner {
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  text-align: left;
  direction: ltr;
}

.text-long {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
