.menu-right {
  background-color: #373F4A;
  height: 100%;
  color: #FFFFFF;
  overflow: hidden;

  .title {
    background: transparent linear-gradient(91deg, #FE9900 0%, var(--unnamed-color-ff867b) 100%) 0% 0% no-repeat padding-box;
    background: transparent linear-gradient(91deg, #FE9900 0%, #FF867B 100%) 0% 0% no-repeat padding-box;
    text-align: center;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .menu-contents {
    height: calc(100% - 60px);
    overflow-y: scroll;
    direction: ltr;

    .menu-contents-inside {
      direction: rtl;
    }
  }

  .menu-item {
  display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 13px 10px;
    color:#FFFFFF;
    &:hover{
      color:$MainColor;
    }
  }
  .menu-item-child{
    color:$colorOrange;
    &:hover{
      color:$MainColor;
    }
  }
  .active-item{
    color:$MainColor;
    background-color: #2A323D;
    position: relative;
    &:before{
      content: '';
      position: absolute;
      width: 4px;
      height: calc(100% - 10px);
      background-color: $MainColor;
      right: 0;
      top:5px;
      border-radius: 5px;
    }
  }
}


.menu-top {
  background-color: #FFFFFF;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .icon{
    font-size: 1.4rem;
    color: #D6D6D6;
  }
}
