.text-wrap-width{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 200px;
  display: inline-block;
}
.dropdown-btn {
  background-color: $BackgroundPublic;
  border-radius: 5px;
  border: 0;
  color: $colorBlack;
&:hover{
  color: $colorBlack;
}
  &:focus {
    color: $colorBlack;
    background-color: #d1d1d1;
  }

  &:active {
    color: $colorBlack;
  }
}

.dropdown-btn-item {
  width: 100%;
  text-align: right;
  padding: 7px;

  &:hover {
    background-color: $BackgroundPublic;
    color: $colorBlack;
  }

  &:focus {
    background-color: #d1d1d1;
  }
}

.table-main {
  table {
    thead {
      tr {
        th {
          color:#B4B4B4;
          border: 0;
          text-align: center;
          font-weight: 500;
        }
      }

    }

    tbody {
      tr {
        border-bottom: 8px solid #fff;

        td {
          border-top: 0;
          background-color: $BackgroundPublic;
          text-align: center;
          color: #646464;
        }
        .check-td-green{
          color: $greenColor;
          border: 1px solid $greenColor;
          border-radius: 5px;
          padding:0 5px;
        }
        .trash-td-pink{
          color: $colorPink;
          border: 1px solid $colorPink;
          border-radius: 5px;
          padding:0 5px;
        }
        .check-td-gray{
          color: $colorGray;
          border: 1px solid $colorGray;
          border-radius: 5px;
          padding:0 5px;
        }

      }
    }
  }
}
