.labelContainer {
  display: block;
  position: relative;
  padding-right: 26px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.labelContainer input {
  position: absolute !important;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 3px;
  right: 0px;
  height: 18px;
  width: 18px;
  background-color: transparent;
  border: 1px solid #5a94ff;
  @include radius(5px);
  @include transition;

}

/* On mouse-over, add a grey background color */
.labelContainer:hover input ~ .checkmark {
  /*  background-color: #ccc;*/
}

/* When the checkbox is checked, add a blue background */
.labelContainer input:checked ~ .checkmark {
  background-color: #5a94ff;
  border: 1px solid #5a94ff;
  @include radius(5px);
  box-shadow: 1px 1px 4px 0px #5a94ff;
  @include transition(.2s);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "\2713";
  position: absolute;
  display: none;
  color: #fff;
  font-size: 0rem;
  @include transition;
  width: 0px;
  height: 0px;
}

/* Show the checkmark when checked */
.labelContainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.labelContainer .checkmark:after {
  right: 3px;
  top: 0px;
  width: 5px;
  height: 9px;
  font-size: .85rem;
  /*  border: solid white;
    border-width: 0 3px 3px 0;*/
  /* -webkit-transform: rotate(45deg);
   -ms-transform: rotate(45deg);
   transform: rotate(45deg);*/
}
