@import "variable";
@import "mixin";

@import '../../../node_modules/antd/dist/antd.css';
@import "../../../node_modules/bootstrap/scss/bootstrap";
@import "../../../node_modules/animate.css";
@import "../persianFont/iransans/css/fontiran.css";

@import "./publicComponents";
@import "./public";
@import "./input";
@import "./checkMark";
@import "./switch";
@import "./checkbox";
@import "./loadingIcon";
@import "./message";
@import "./404";
@import "./main";
@import "./menu";
@import "./leaflet";
@import "./jvectormap";

/*------------------------------------------------terminal*/
@import "./terminal";

/*------------------------s*/
@import "./login";

/*------------------------dashboard*/
@import "./card";

/*------------------------diver*/
@import "./driver";

/*------------------------magazine*/
@import "./magazine";

/*------------------------cargo*/
@import "./cargo";

/*------------------------area*/
@import "./area";