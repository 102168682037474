.card-intro{
display: flex;
  align-items: center;
  justify-content: space-between;
    border-radius: 5px;
    box-shadow: 1px 1px 8px #9a9a9a75;
    background-color: #fff;
    text-align: right;
  height: 100%;
    &:hover{
      box-shadow: 1px 1px 8px #9a9a9a75;
    }
    &:focus{
      box-shadow: 1px 1px 8px #9a9a9a75;
    }


}
