$S: 576px;
$M: 768px;
$L: 992px;
$X: 1200px;


$greenColor: #5a94ff;
$colorGray: #85898d;
$goldColor: #E3CA92;
$redColor: #b1111b;
$BackgroundPublic:#f7f7f7;
$colorWhite: #fff;
$MainColor: #fe886d;
$MainColorLight: #fe98191f;
$colorOrange: #FFEDD6;
/*$Gray: #85898d8c;*/
$Gray: #c4c5c547;
$GrayReal: #c4c5c5;
$colorPink: #ff808b;
$ColorMainDark: #fe886d;
$ColorMainDark2: #fe9901;
$colorBlack: #3a3a3a;
$silver:#b3b7c1;
$IconNext: 'http://ofoghplus.com/l/icon/next.svg';
$IconMenu: 'http://ofoghplus.com/l/icon/menu.svg';

$border-radius-5 : 5px;
$border-radius-10 : 10px;
$border-radius-50 : 50px;
$border-radius-100 : 100px;
