.filter-head {
  background-color: #fff;
  border-radius: 100px;
  text-align: center;
  color: $colorBlack;
  font-size: .9rem;
}

.tabs {
  border-bottom: 2px solid #eee;
  .nav-link{
    color: $colorGray;
    text-align: center;
    cursor: pointer;
    flex-grow: 1;
    &:hover{
      color: $colorBlack
    }
  }

  .active  {
    position: relative;
    font-weight: 600;
    color: $colorBlack;
    &:after {
      content: '';
      position: absolute;
      bottom: -5px;
      right: 0;
      width: 100%;
      height: 2px;
      background-color: $MainColor;

    }
  }
}
.modal-head-style {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color:#fff;
}

.collapseStyle {
  background-color: #fff7eb ;
  color: $colorBlack;
  text-align: right;
  margin: 0;


}
.box-height-modal{
  height: 85vh;
  overflow-y: scroll;
  position: relative;
  padding-bottom: 110px;
  .collapseStyle{
    overflow-x: scroll;
  }
}
.table-main{
  width: 100%;
  overflow-x: scroll
}
